.notConnectedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.notConnectedContainer p {
  margin-top: 2rem;
  font-size: 16px;
}
.notConnectedContainer button {
  margin: 1rem 0;
}
