.sideCardBody {
  font-size: 14px;
  margin-bottom: 0;
}
.title {
  font-size: 18px;
  margin-bottom: 0;
}
.subtitle {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--brand-gray);
}
.subtitle b div {
  display: inline;
}
.icon {
  width: 44px;
  height: 44px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.purpleText {
  color: var(--brand-purple);
}
.purpleIcon {
  background: var(--brand-purple-hint);
  border: 1px solid var(--brand-purple-semi-transparent);
}
.purpleIcon svg {
  color: var(--brand-purple);
}
.divider {
  margin: 18px 0px;
}

.totalVotes {
  color: var(--brand-gray);
}
.votingInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.votingBar {
  border-radius: 50px;
  margin: 12px 0px;
  background: var(--brand-purple-hint);
}

.list {
  color: var(--brand-gray);
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.list b {
  color: var(--brand-black);
}

.listItem {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin: 4px 0;
}

.loadingCopy {
  display: flex;
}
.icon {
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  border-radius: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--brand-gray-hint);
  border: 1px solid var(--brand-gray-semi-transparent);
  color: var(--brand-black);
}
