@font-face {
  font-family: 'PT Root UI';
  src: url('./assets/fonts/PT-Root-UI_Bold.woff2') format('woff2'),
    url('./assets/fonts/PT-Root-UI_Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('./assets/fonts/PT-Root-UI_Medium.woff2') format('woff2'),
    url('./assets/fonts/PT-Root-UI_Medium.woff') format('woff');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('./assets/fonts/PT-Root-UI_Regular.woff2') format('woff2'),
    url('./assets/fonts/PT-Root-UI_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('./assets/fonts/PT-Root-UI_Light.woff2') format('woff2'),
    url('./assets/fonts/PT-Root-UI_Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid&display=swap');

* {
  font-family: 'PT Root UI';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body {
  letter-spacing: -0.2px;
  background-color: #ffffff;
}
.bgLightPurple {
  background-color: #dbd3fd;
}
.bgPurple {
  background-color: #8a2be2;
}
.bgGray {
  background-color: #f5f5f5;
}
.bgWhite {
  background-color: #ffffff;
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
}
.homeGradientBg {
  background: url('../src/assets/small-pink-pattern@2x.png'),
    linear-gradient(180deg, rgba(245, 245, 245, 0) 82.54%, #f5f5f5 100%),
    radial-gradient(
      19.94% 82.15% at 100% 0%,
      rgba(203, 167, 39, 0.2) 0%,
      rgba(203, 167, 39, 0) 100%
    ),
    radial-gradient(
      73.64% 129.99% at -5.75% 0%,
      rgba(80, 186, 154, 0.265) 0%,
      rgba(80, 186, 154, 0) 65.01%
    ),
    radial-gradient(
      73.21% 132.35% at 20.6% -17.55%,
      rgba(138, 43, 226, 0.2) 0%,
      rgba(138, 43, 226, 0) 100%
    ),
    radial-gradient(
      84.88% 125.84% at 100% 0%,
      rgba(224, 46, 207, 0.091) 0%,
      rgba(224, 46, 207, 0) 100%
    ),
    radial-gradient(
      84.28% 100% at 50% 0%,
      rgba(138, 43, 226, 0.12) 0%,
      rgba(224, 46, 207, 0.016) 100%
    );
}

.gradientBg {
  background: linear-gradient(180deg, rgba(245, 245, 245, 0) 78.1%, #f5f5f5 100%),
    radial-gradient(
        19.94% 82.15% at 100% 0%,
        rgba(203, 167, 39, 0.2) 0%,
        rgba(203, 167, 39, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        73.64% 129.99% at -5.75% 0%,
        rgba(80, 186, 154, 0.265) 0%,
        rgba(80, 186, 154, 0) 65.01%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        73.21% 132.35% at 20.6% -17.55%,
        rgba(138, 43, 226, 0.2) 0%,
        rgba(138, 43, 226, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        84.88% 125.84% at 100% 0%,
        rgba(224, 46, 207, 0.091) 0%,
        rgba(224, 46, 207, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}
