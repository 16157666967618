.imgContainer {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--bg-med);
  border: 1px solid var(--border-med);
}
.img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
}

.upload p {
  margin-top: 6px;
  font-style: italic;
  font-size: 12px;
  color: var(--brand-gray);
}

.error {
  color: red;
  font-size: 12px;
  font-weight: 700;
  font-style: italic;
}
@media (max-width: 575px) {
  .container {
    flex-direction: column-reverse !important;
  }
  .upload {
    flex-direction: row !important;
    justify-content: space-evenly;
  }
  .upload button {
    height: min-content;
    align-self: center;
    width: 100%;
  }
}
