.backToAuction {
  color: var(--brand-black-transparent);
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  width: max-content;
  align-items: center;
  font-size: 1rem;
}

.backToAuction:hover {
  color: var(--brand-black);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.gradient {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10rem;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}
.votingBar {
  position: fixed;
  width: inherit;
  z-index: 10;
  bottom: 2rem !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding: 13px 20px;
  border-radius: 12px;
  background-color: white;
  min-height: 4.5rem;
  max-height: 4.5rem;
  display: flex;
}
.votingBar.connectBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.votingBarContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: var(--brand-black-transparent);
  width: 100%;
}
.votingBarContent b {
  color: var(--brand-black);
}
.inlineClick {
  color: var(--brand-pink);
  font-weight: bold;
}
.inlineClick:hover {
  cursor: pointer;
}

.loadingVoting {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 1200px) {
  .votingBar {
    left: 0;
    width: calc(100% - 24px);
    margin: 0 12px;
  }
}

@media (max-width: 760px) {
  .votingBar {
    max-height: max-content;
  }
  .votingBarContent {
    text-align: center;
    flex-direction: column;
    row-gap: 1rem;
  }
  .votingBarContent button {
    width: 100%;
  }
}

@media (min-width: 577px) {
  .backToAuction svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}
