.footerContainer {
  padding: 6rem 0 3rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  flex: 1 1;
}

.footer {
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: bold;
  color: var(--brand-pink) !important;
}

.footer a:hover {
  text-decoration: underline !important;
}

@media (max-width: 575px) {
  .footerContainer {
    padding: 4rem 20px 3rem 20px;
  }
}

@media (max-width: 375px) {
  .footer {
    font-size: 14px !important;
  }
}
