.cancelRoundCard {
  width: 25rem;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.cancelRoundText {
  font-size: 16px;

  margin-bottom: 2rem;
}
.cancelRoundCard button {
  width: 100%;
}
