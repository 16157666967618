.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
}
.container .rankingTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1rem;
  color: var(--brand-gray);
  display: flex;
  align-items: center;
}
.container .rankingTitle svg {
  margin-right: 6px;
}
.container .item {
  font-size: 16px;
  display: flex;
  border-bottom: 1px var(--brand-gray-hint) solid;
  padding-bottom: 6px;
  margin-bottom: 6px;
  color: var(--brand-gray);
}
.container .item .place {
  min-width: 16px;
}
.container .item .avatar {
  flex-shrink: 0;
  margin-right: 6px;
}
.container .item .title {
  color: var(--brand-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container .item:nth-child(4) {
  border-bottom: none;
}
.container .item span {
  color: var(--brand-black);
}
.container .item .votingPower {
  flex-shrink: 0;
  margin-left: auto;
}
.noProps {
  font-style: italic;
  color: var(--brand-gray);
}
