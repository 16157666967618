.breakOut {
  width: 100vw;
  max-width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}

.breakOut img {
  max-width: 100%;
}
.noPadding {
  padding: 0;
}

.headerRow {
  height: 75vh;
}
.title {
  font-size: 40px;
  font-weight: bold;
}
.subtitle {
  font-size: 18px;
}

.headerRow .title {
  margin-top: 2rem;
  font-size: 60px;
}
.headerRow .subtitle {
  font-size: 25px;
  color: var(--brand-gray);
}

.headerImg {
  position: absolute;
  right: 0;
  padding-right: 0;
  max-height: 100%;
  height: 70vh;
  object-fit: cover;
  width: auto;
  z-index: 999;
  pointer-events: none;
}

.btnContainer {
  margin-top: 1rem;
  display: flex;
  gap: 12px;
}
.statsRowSvgTop {
  background-color: #dbd3fd;
}
.statsRowSvgTop path,
.statsRowSvgBottom path {
  fill: var(--brand-purple);
}
.statsRowSvgBottom {
  background-color: white;
  z-index: 0;
}
.statsRowContainer {
  background: var(--brand-purple);
  margin-top: -20vh;
}
.statsRow {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
}
.statsRow .title {
  color: white;
  padding-bottom: 0;
  margin-bottom: 1rem;
}
.statsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 70px;
  font-size: 30px;
}
.statsItem {
  display: flex;
  flex-direction: column;
}
.statsItem .stat {
  font-size: 50px;
  font-weight: bold;
}
.statsItem .title {
  font-size: 20px;
}

.onchainCityRow {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.onchainCityTitleCol {
  position: relative;
  bottom: -6rem;
}

.onchainCityRow img {
  position: relative;
  left: 4rem;
  width: 75vw;
  margin-top: 0;
  z-index: 2;
}

.onchainCityRowSvgBottom {
  background-color: transparent;
  padding: 0;
  z-index: 0;
}
.onchainCityRowSvgBottom path {
  fill: white;
}
.alienRow {
  background-color: transparent;
}
.alienRow .title {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.alienImgCol {
  display: flex;
  justify-content: center;
}
.alienImgCol img {
  max-width: 50vh;
  width: auto;
  position: relative;
  z-index: 999 !important;
}
.featuresContainer {
  padding-bottom: 2rem;
}
.featuresContainer b {
  font-size: 18px;
}
.featuresContainer span {
  font-size: 16px;
  text-align: left;
}
.featureItem {
  display: flex;
  flex-direction: column;
  height: 10rem;
}
.roundsvgTopRow img {
  position: absolute;
  width: 25vw;
  z-index: 999;
}
.roundsvgTopRow .hand {
  left: 0;
  margin-top: -10vh;
}
.roundsvgTopRow .moon {
  right: 0;
  margin-top: -10vh;
}

.roundsRowSvgTop {
  background-color: transparent !important;
}
.roundsRowSvgTop path {
  fill: var(--brand-purple);
  z-index: 0 !important;
}
.roundsRowContainer {
  background-color: var(--brand-purple);
}

.roundsRow {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.roundsRow .btnContainer {
  align-items: center;
  justify-content: center;
}

.roundRowTitle {
  color: white;
  text-align: center;
  margin-bottom: 2rem;
}
@media (min-width: 1600px) {
  .statsRowContainer {
    margin-top: -20vh;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .statsRowContainer {
    margin-top: -25vh;
  }
  .headerImg {
    height: 60vh;
  }
  .roundsvgTopRow .hand {
    margin-top: -7vh;
  }
  .roundsvgTopRow .moon {
    margin-top: -7vh;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .statsRowContainer {
    margin-top: -30vh;
  }
  .headerImg {
    height: 55vh;
  }
  .roundsvgTopRow .hand {
    margin-top: -4vh;
  }
  .roundsvgTopRow .moon {
    margin-top: -4vh;
  }
}
@media (max-width: 992px) {
  .alienRow {
    text-align: left;
    margin-top: 0;
    padding-top: 2rem;
  }
  .alienRow img {
    max-width: 60vw;
  }
  .featureItem {
    height: initial;
    align-items: flex-start;
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .headerImg {
    height: 40vh;
    margin-top: -30vh;
  }
  .statsRowContainer {
    margin-top: -22vh;
  }
  .roundsvgTopRow .hand {
    margin-top: -2vh;
  }
  .roundsvgTopRow .moon {
    margin-top: -2vh;
  }
  .statsContainer {
    gap: 50px;
  }
  .statsItem .stat {
    font-size: 40px;
    font-weight: bold;
  }
  .statsItem .title {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .onchainCityTitleCol {
    padding: 1rem 0;
    text-align: center;
    position: initial;
    bottom: initial;
  }
  .onchainCityTitleCol .subtitle {
    margin-bottom: 2rem !important;
  }

  .onchainCityRow img {
    position: static;
    left: initial;
    width: 100vw;
  }

  .onchainCityRow img {
    width: 100vw;
  }

  .statsContainer {
    gap: 40px;
  }
  .statsItem .stat {
    font-size: 32px;
    font-weight: bold;
  }
  .statsItem .title {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .headerImg {
    height: 35vh;
    margin-top: -17vh;
  }
  .statsRowContainer {
    margin-top: 3vh;
  }
  .statsRow .title {
    font-size: 26px;
  }
  .trustedByContainer {
    gap: 10px;
    font-size: 16px;
  }
  .roundsvgTopRow img {
    width: 40vw;
  }
  .roundsvgTopRow .hand {
    margin-top: -5vh;
  }
  .roundsvgTopRow .moon {
    margin-top: -5vh;
  }
  .roundRowTitle {
    padding-top: 2rem;
  }
  .statsContainer {
    gap: 20px;
  }
  .statsItem .stat {
    font-size: 20px;
    font-weight: bold;
  }
  .statsItem .title {
    font-size: 12px;
  }
  .alienRow .title {
    justify-content: left;
  }
}
