.invalidAddressCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5rem;
}
.invalidAddressCard img {
  width: auto;
  border-radius: 25px;
  height: 15rem;
  margin-right: 10rem;
}
.textContainer {
  text-align: left;
}
.textContainer p {
  margin-top: 1rem;
}
.textContainer button {
  margin: 16px 12px 12px 0;
}

@media (max-width: 1200px) {
  .invalidAddressCard {
    flex-direction: column;
  }
  .invalidAddressCard img {
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .invalidAddressCard {
    margin-top: 0;
  }
  .imgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgContainer img {
    margin-right: 0;
  }
}
