.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.buttons {
  flex-wrap: wrap;
}
.strategyButton {
  width: 100%;
  border-radius: 10px !important;
}
.tokenButton {
  border-radius: 10px;
  padding: 6px 12px !important;
  white-space: normal;
  text-align: center;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 140%;
  gap: 5px;
  display: flex;
  align-items: center !important;
}
.tokenButton div {
  margin: auto;
}
.tokenImage {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.tokenButton:disabled {
  background-color: var(--brand-gray);
}
.btnPurpleBg {
  background-color: var(--brand-purple);
  color: white !important;
  border: none;
}
.btnWhiteBg {
  background-color: white;
  color: black;
  border-color: rgba(0, 0, 0, 0.1);
}
.btnWhiteBg:hover {
  color: gray;
  background-color: white !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.btnWhiteBg:active,
.btnWhiteBg:focus {
  color: gray !important;
  background-color: var(--brand-gray-semi-transparent);
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.btnWhiteBg:disabled {
  background-color: white;
  color: var(--brand-gray-transparent);
  border: 1px solid var(--border-med);
}

@media (max-width: 500px) {
  .strategyButton {
    min-width: 120px;
  }
}
@media (max-width: 450px) {
  .strategyButton {
    min-width: 100px;
  }
}
