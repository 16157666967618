.row {
  margin: 2rem initial;
}
.subheading {
  font-size: 18px;
  font-weight: bold;
  color: var(--brand-gray-transparent);
  padding: 0;
  margin-bottom: 1rem;
}
.subheading:nth-child(1) {
  margin-top: 1rem;
}

.noRoundsDiv {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.noRoundsDiv img {
  width: 10rem;
}
.noRoundsDiv .rightCol {
  display: flex;
  flex-direction: column;
}
.noRoundsDiv .rightCol p {
  font-size: 16px;
  margin-bottom: 1rem !important;
}
.noRoundsDiv .rightCol button {
  width: fit-content;
}
