@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-xl,
  .container-xxl {
    width: 992px !important;
    max-width: 992px !important;
  }
}
@media (max-width: 767px) {
  .container,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: none !important;
  }
}
@media (max-width: 991px) {
  .manager .container,
  .manager [class^='container-'] {
    max-width: none !important;
  }
  .wrapper > .container:has(.navbar) {
    max-width: none !important;
  }
}

body {
  background-color: rgb(250, 250, 250);
}
p {
  margin-bottom: 0 !important;
}
.tooltip-inner {
  min-width: min-content;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--brand-gray);
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--brand-gray);
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--brand-gray);
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--brand-gray);
}

.markdown a {
  color: var(--brand-pink) !important;
  font-weight: bold;
}

:root {
  /* Pink */
  --brand-pink: #e02ecf;
  --brand-pink-transparent: rgba(224, 46, 207, 0.6);
  --brand-pink-semi-transparent: rgba(224, 46, 207, 0.25);
  --brand-pink-hint: rgba(224, 46, 207, 0.08);

  /* Purple */
  --brand-purple: #8a2be2;
  --brand-purple-transparent: rgba(138, 43, 226, 0.6);
  --brand-purple-semi-transparent: rgba(138, 43, 226, 0.25);
  --brand-purple-hint: rgba(138, 43, 226, 0.08);

  /* Yellow */
  --brand-yellow: #cba727;
  --brand-yellow-transparent: rgba(203, 167, 39, 0.78);
  --brand-yellow-semi-transparent: rgba(203, 167, 39, 0.3);
  --brand-yellow-hint: rgba(203, 167, 39, 0.1);

  /* Green */
  --brand-green: #50ba9a;
  --brand-green-transparent: rgba(80, 186, 154, 0.78);
  --brand-green-semi-transparent: rgba(80, 186, 154, 0.3);
  --brand-green-hint: rgba(80, 186, 154, 0.1);

  /* Black */
  --brand-black: #14161b;
  --brand-black-transparent: rgba(20, 22, 27, 0.6);
  --brand-black-semi-transparent: rgba(20, 22, 27, 0.25);

  /* Red */
  --brand-red: #e02e2e;
  --brand-red-transparent: rgba(224, 46, 46, 0.6);
  --brand-red-semi-transparent: rgba(224, 46, 46, 0.25);
  --brand-red-hint: rgba(224, 46, 46, 0.1);

  /* Gray */
  --brand-gray: #666666;
  --brand-gray-transparent: rgba(102, 102, 102, 0.6);
  --brand-gray-semi-transparent: rgba(102, 102, 102, 0.25);
  --brand-gray-hint: rgba(102, 102, 102, 0.1);

  /* Utils */
  --bg-light: #fff;
  --bg-med: #fcfcfc;
  --bg-dark: #f5f5f5;

  --border-light: rgba(0, 0, 0, 0.04);
  --border-med: rgba(0, 0, 0, 0.1);
  --border-dark: rgba(102, 102, 102, 0.25);

  --shadow-none: 0px 0px 0px rgba(0, 0, 0, 0);
  --shadow-low: 0px 4px 12px rgba(0, 0, 0, 0.03);
  --shadow-high: 0px 2px 8px rgba(0, 0, 0, 0.08);

  --card-transition: 0.15s cubic-bezier(0, 0.41, 0.6, 1.02);
}

/* breaks div out of the bootstrap .container to become full width with the viewport */
.break-out {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}

/* set react tooltip bg opacity */
.tooltip.show {
  opacity: 1;
  white-space: pre;
}

@media (max-width: 993px) {
  /* language selector on mobile */
  .navbar-nav .dropdown-menu {
    position: absolute !important;
  }
}

/* dropdown-basic */
.phDropdown .dropdown button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 1rem;
}
.phDropdown .dropdown button,
.phDropdown .show > .btn-success.dropdown-toggle {
  background: transparent;
  width: 100%;
  height: 38px;
  font-weight: 600;
  color: var(--brand-black);
  border-radius: 10px;
  border: 1px solid var(--border-med);
}
.phDropdown .show {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.phDropdown .dropdown .dropdown-menu.show {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 2px;
  width: 100%;
}
.phDropdown .dropdown-item {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  height: 38px;
  align-items: center;
}
.phDropdown .dropdown-item:active,
.phDropdown .dropdown-item:focus,
.phDropdown .dropdown-item:hover {
  background: var(--border-light);
  border-radius: 10px;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:focus-visible {
  color: var(--brand-black) !important;
  outline: none !important;
  box-shadow: none !important;
  background: var(--bg-med);
  border: 1px solid rgba(102, 102, 102, 0.6);
}
/* voting progress bar */
.bg-success {
  background: var(--brand-purple) !important;
  border-radius: 6px 2px 2px 6px;
}
.bg-warning {
  background: var(--brand-purple-transparent) !important;

  /* only round border when there are already submitted votes
  as in we're ADDING more than one. so numAllotedVotes > 0 && .roundBorder */
}
/* .bg-warning */
.roundAllotmentBar .bg-warning {
  border-radius: 2px 6px 6px 2px !important;
  margin-left: 2px;
}

/* modals */
.ReactModal__Overlay--after-open {
  backdrop-filter: blur(5px) !important;
  z-index: 3;
}
.ReactModal__Content {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-height: max-content !important;
}

.proposalModalContainer {
  max-height: 100% !important;
  overflow-y: scroll !important;
  overflow-y: overlay !important;
}
.proposalModalContainer .container {
  min-width: 100%;
}

@media (max-width: 475px) {
  .footer .voteInput,
  .footer .votingBtns button {
    height: 38px;
    width: 38px;
  }
}

.scrollMoreContainer:before {
  content: '';
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 300px;
  bottom: 0px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%);
}
@media (max-width: 575px) {
  .scrollMoreContainer {
    display: none;
  }
}

/* blot formatter rich text editor */
.blot-formatter__toolbar {
  display: none;
}
/* styles added to image uploader when a file is dragged on */
.dragover {
  background-color: var(--bg-dark) !important;
  border: 2px dashed var(--brand-green) !important;
}
/* Proposal styles */
.proposalCopy {
  word-break: break-word;
}
.proposalCopy h1 {
  font-family: 'PT Root UI' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 25px !important;
  letter-spacing: 0.02em !important;
}
.proposalCopy h2 {
  font-family: 'PT Root UI' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  margin-top: 15px !important;
  letter-spacing: 0.02em !important;
  color: var(--brand-black) !important;
}
.proposalCopy img {
  max-width: 100% !important;
}
.proposalCopy :is(p, li) {
  font-family: 'PT Root UI' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  letter-spacing: 0.02em !important;
  margin-bottom: 0 !important;
}

.proposalCopy blockquote {
  font-family: 'PT Root UI';
  color: var(--brand-black) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  letter-spacing: 0.02em !important;
  margin: 0 !important;
  margin-left: 20px !important;
  margin-bottom: 10px !important;
  border-left-color: rgba(102, 102, 102, 0.25) !important;
  border-left-width: 4px !important;
  border-left-style: solid !important;
  padding-left: 16px !important;
}
.proposalCopy a {
  color: var(--brand-purple) !important;
  text-decoration: underline !important;
}
.proposalCopy a:hover {
  text-decoration: underline !important;
}
.proposalCopy :is(code, pre) {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 13px !important;
  background: #2f3136 !important;
  color: white !important;
  padding: 22px !important;
  white-space: pre-wrap;
}
.proposalCopy ul {
  margin-bottom: 0 !important;
}
.proposalCopy {
  padding: 0 !important;
}
