.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 500px;
}
.modal {
  font-family: 'PT Root UI', sans-serif;
  background: white;
  z-index: 100;
  backdrop-filter: blur(10px);
  border: 1px solid var(--border-dark);
  box-shadow: var(--shadow-light);
  border-radius: 24px;
  padding: 18px;
  width: 100%;
  max-width: 500px;
  min-width: 420px;
  max-height: 90% !important;
  overflow-y: scroll;
  padding-bottom: 0px !important;
}
.titleContainer {
  text-align: center;
  justify-content: space-between;
  display: flex;
  gap: 10px;
}
.titleContainer p {
  margin-bottom: 0;
}
.titleAndSubtitleContainer {
  width: 100%;
}
.gap {
  width: 42px !important;
}
.modalTitle {
  font-weight: 700;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}
.modalSubtitle {
  font-weight: 500;
  font-size: 16px;
  color: var(--brand-gray);
  text-align: center;
}
.modal button {
  border-radius: 50px;
}

.buttonContainer {
  display: flex;
  gap: 8px;
}
.modal:focus-within {
  outline: none !important;
}
.imgContainer {
  text-align: center;
  padding: 35px 0px 25px 0px;
  width: 100%;
}
.imgContainer img {
  height: 80px;
}
.body {
  overflow-y: scroll;
  padding-top: 10px;
}
.footer {
  position: sticky !important;
  bottom: -1px;
  bottom: 0px;
  z-index: 1;
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.04) !important;
  width: 100%;
  max-width: 100%;
  z-index: 10 !important;
  padding-bottom: 18px;
  padding-top: 18px;
  margin-top: 18px;
}
.noFooter {
  border-top: none !important;
  margin-top: 0px;
}
.body::-webkit-scrollbar {
  width: 7px;
  height: 0px;
}
.body::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 75px;
  margin-bottom: 20px;
}
.body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: white;
  background: var(--brand-gray-semi-transparent);
}
.footer .buttonContainer button {
  width: 100% !important;
}
@media (max-width: 575px) {
  .modal {
    width: 90%;
    min-width: 300px;
  }
  .fullScreenOnMobile {
    border-radius: 0px !important;
    min-height: 100% !important;
    min-width: 100% !important;
    height: 100vh;
    display: flex;
    overscroll-behavior-y: none;
  }
  .fullScreenOnMobile .container {
    min-width: 100% !important;
    min-height: 100% !important;
  }

  .titleContainer {
    text-align: left;
    display: flex;
  }

  .fullScreenOnMobile .modalTitle {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: clip !important;
    padding-bottom: 0px;
  }
  .fullScreenOnMobile .titleContainer {
    text-align: left;
  }
  .fullScreenOnMobile .modalTitle {
    margin-right: 5px;
  }
  .fullScreenOnMobile .body {
    flex-grow: 1;
    display: grid;
    overflow-y: auto;
  }
}
.closeBtn {
  border: 1px solid var(--border-dark);
  color: var(--brand-gray);
  cursor: pointer;
  font-size: 1.25rem;
  width: 34px;
  height: 34px;
  display: flex;
  padding: 4px;
  border-radius: 10px;
}

.closeBtn:hover {
  background: var(--border-light);
}
