.banner {
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  background-color: var(--brand-purple);
  color: white;
}
.banner div a {
  font-weight: bold;
  text-decoration: underline !important;
}
