.roundCard {
  min-height: 17rem;
  height: 17rem;
  max-height: 17rem;
  position: relative;
  z-index: 1;
  border: 1px solid var(--border-med);
  cursor: pointer;
  height: auto;
  max-height: 30rem;
}
.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  padding: 8px;
}
.container .leftCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.roundCreator {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: bold;
  color: var(--brand-gray);
}
.roundCreator img {
  height: 20px;
  width: 20px;
  border-radius: 10px;
}

.roundTitle {
  font-size: 32px;
  font-weight: bold;
  line-height: 115%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.container .leftCol .headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container .leftCol .headerContainer .roundCreatorAndTitle {
  display: flex;
  flex-direction: column;
}

.container .leftCol .statusItemContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1.2rem;
  margin-top: 1rem;
}
.container .leftCol .statusItemContainer .item {
  display: flex;
  flex-direction: column;
}
.container .leftCol .statusItemContainer .item:nth-child(2),
.container .leftCol .statusItemContainer .item:nth-child(3) {
  border-left: 1px var(--brand-gray-hint) solid;
  padding-left: 1rem;
}
.container .leftCol .statusItemContainer .item .title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: medium;
  color: var(--brand-gray-transparent);
  margin-bottom: 6px;
}
.container .leftCol .statusItemContainer .item .title svg {
  margin-right: 6px;
}
.container .leftCol .statusItemContainer .item .content {
  font-weight: bold;
  font-size: 22px;
}

.awardsModalContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.awardsModalContentContainer .place {
  font-weight: bold;
}
.awardsModalContentContainer .amountAndSymbol {
  font-weight: bold;
  color: var(--brand-gray);
}

.container .rightCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1.5rem;
  border-top: 1px solid var(--border-med);
  padding-top: 1rem;
  margin-top: 1rem;
  padding-left: 12px;
}

.container .rightCol .awardsContainer .title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--brand-gray-transparent);
  margin-bottom: 0px;
}
.container .rightCol .awardsContainer .title svg {
  margin-right: 6px;
}

.container .rightCol button {
  width: 100%;
  margin-top: 12px;
}
@media (min-width: 768px) {
  .roundCard.displayHorizontal {
    height: 17rem;
    max-height: 17rem;
  }
  .container .leftCol .statusItemContainer.displayHorizontal {
    margin-top: 0rem;
  }
  .container .rightCol.displayHorizontal {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
    padding-left: 0;
  }
}
