.ethAddress a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  color: #666666 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  width: max-content;
}

.ethAddress a:visited {
  color: inherit;
}
.img {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
