.headerContainer {
  display: flex;
  flex-direction: column;
}
.headerBottomContainer {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.fundReq {
  font-weight: 900;
  color: var(--brand-black-transparent);
  font-size: 1rem;
  display: flex;
  align-items: center;
}

hr {
  background-color: var(--brand-gray-semi-transparent);
}

.subinfo {
  display: flex;
  color: var(--brand-black);
  font-weight: 700;
  flex-direction: row;
}

.subinfo a {
  font-weight: 650;
}

.propSubmissionData {
  display: flex;
}
.submittedBy {
  display: flex;
  align-items: flex-end;
  color: var(--brand-pink);
  font-weight: 700;
  font-size: 1rem;
  gap: 5px;
}
.submittedBy a {
  text-decoration: none !important;
}

.textSpacer {
  padding: 0px 5px;
}

.markdown a {
  color: var(--brand-pink) !important;
  text-decoration: underline !important;
}
.markdown img {
  max-width: 100%;
  height: auto;
}

.propBy {
  display: flex;
  align-items: center;
  color: var(--brand-gray) !important;
  gap: 5px;
}

.communityProfImg {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin-right: 4px;
}
.backBtnContainer {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 700;
  color: var(--brand-gray);
}

@media (max-width: 577px) {
  .backBtnContainer {
    padding-bottom: 25px;
  }
  .propSpacer {
    display: none !important;
  }
  .subinfo {
    flex-direction: column;
    gap: 5px;
  }
}
