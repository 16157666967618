.container {
  display: flex;
  align-items: center;
  color: var(--brand-gray-transparent);
  font-size: 14px;
}
.container .avatars {
  display: flex;
  width: auto;
  padding-right: 12px;
}
.container .avatars div {
  margin-right: -6px !important;
  border: 1px white solid;
  border-radius: 7px;
}
.container span {
  color: var(--brand-black);
}

@media (max-width: 575px) {
  .container {
    margin-top: 12px;
  }
}
