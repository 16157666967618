.roundHeaderRow {
  display: flex;
  padding-bottom: 2rem;
}
.houseNameRow {
  display: flex;
  justify-content: space-between;
}
.manageRoundBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--brand-black);
}
.manageRoundBtn svg {
  margin-left: 6px;
}
.profilePicCol img {
  height: 15rem;
  width: auto;
  border-radius: 30px !important;
}
.communityInfoCol {
  display: flex;
  flex-direction: column;
}
.communityInfoCol > * {
  margin: 0.2rem 0;
}
.communityInfoCol .date {
  color: var(--brand-pink) !important;
  font-weight: bold;
}
.titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  flex: 0;
}
.titleRow .title {
  font-size: xx-large;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.titleRow .contractAddressPill {
  background-color: rgba(128, 128, 128, 0.1);
  font-weight: 400;
  padding: 0.4rem 0.6rem;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 1rem;
  letter-spacing: 0.2px;
}

.communityDescriptionRow p {
  font-size: large;
  color: var(--brand-gray);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.communityDescriptionRow a {
  color: var(--brand-pink) !important;
  text-decoration: underline;
  font-weight: 600;
}
.communityDescriptionRow a:hover {
  text-decoration: underline !important;
  transition: all 0.15s ease-in-out;
}

.backToHouse {
  color: rgba(20, 22, 27, 0.6);
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  width: max-content;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.backToHouse:hover {
  color: var(--brand-black);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.backToHouse .profImg {
  width: 26px;
  height: 26px;
  border-radius: 13px;
}
.backToHouse .commTitle {
  margin-left: 5px;
}

.stickyContainer {
  background: white;
  top: 0;
  position: sticky !important;
  z-index: 1;
  padding-top: 12px;
}

.awardsCol {
  border-left: 1px var(--border-light) solid;
  padding-left: 2rem;
}
.awardsTitle {
  font-size: 20px;
  font-weight: bold;
  color: var(--brand-black);
}
.awardsSubtitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--brand-gray);
  margin-bottom: 1.2rem;
}
@media (max-width: 992px) {
  .titleRow {
    align-items: flex-start;
  }
  .roundHeaderRow {
    margin-bottom: 0rem;
  }
  .profilePicCol {
    text-align: center;
    margin-bottom: 1rem;
  }
  .communityDescriptionRow p {
    -webkit-line-clamp: 10;
    line-clamp: 10;
  }

  .phLink {
    margin-top: 1rem;
  }
  .longName {
    flex-direction: column;
  }
  .longName .contractAddressPill {
    margin-left: 0 !important;
  }
  .awardsCol {
    padding-left: 12px;
  }
}

@media (max-width: 576px) {
  .backToHouse {
    margin-bottom: 12px;
  }
  .awardsSubtitle {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 575px) {
  .stickyContainer {
    padding: 12px 0px 8px 0px;
  }
  .houseNameRow {
    display: flex;
    flex-direction: column;
  }
  .manageRoundBtn {
    width: 100%;
    margin-bottom: 12px;
  }
}
