.awardsAndNavigationContainer {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.swiper {
  padding: 14px 0 0 0 !important; /** gives room for placement circles */
  max-width: 100%;
}
.swiperSlide {
  width: auto;
}
.breakOut {
  width: 100vw;
  max-width: none !important;
  position: relative;
  left: -12px;
  height: max-content !important;
}
.navContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  margin-top: 12px;
}
.awardsNavControl {
  background-color: white;
  color: var(--brand-gray);
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px var(--border-dark) solid;
}
.awardsNavControl:hover {
  opacity: 0.75;
  cursor: pointer;
}
.awardsNavControl:disabled {
  background-color: var(--bg-dark);
}

.awardsNavControl:disabled:hover {
  opacity: 1;
  cursor: default;
}

.awardDisplay {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 88px;
  height: 88px;
  padding: 8px 0;
  background-color: white;
  border: 1px var(--border-light) solid;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.awardDisplay .placeIndicator {
  z-index: 2;
  position: absolute;
  top: -15px;
  right: -12px;
  background-color: var(--bg-med);
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13.5px;
  border: 1px var(--border-dark) solid;
  font-size: 11px;
  font-weight: bold;
  color: var(--brand-gray);
  z-index: 99999;
}
.awardDisplayPadding {
  padding: 12px 0;
}

.awardDisplay img {
  width: 35px;
  height: 35px;
  object-fit: contain;
  border-radius: 8px;
}
.awardDisplay .fullImg {
  width: 88px;
  height: 88px;
}

.awardDisplay .amountAndSymbolLabel {
  margin-top: 12px;
  font-size: 12px;
  font-weight: bold;
  color: var(--brand-gray);
}
