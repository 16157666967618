.row {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  overflow: hidden;
}

.imageContainer {
  overflow: hidden;
  background: #fff;
  line-height: 1px;
  min-width: 14px;
}
.image {
  height: 14px;
  width: 14px;
  border-radius: 2px !important;
}

.awardNameRow {
  display: flex;
  align-items: center;
}

.amount {
  color: var(--brand-gray);
  font-weight: 700 !important;
}
.progressBar {
  width: 100%;
}
.progressBar :global(.progress) {
  height: 0.25rem !important;
  background: white;
}
.progressBar :global(.progress) :global(.progress-bar):first-child {
  background-color: var(--brand-purple);
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.progressBar :global(.progress) :global(.progress-bar):nth-child(2) {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.progressBar :global(.bg-warning) {
  background-color: var(--border-med) !important;
}
.addAsset {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:is(.allText, .addAssetText) {
  font-size: 12px;
}
.addAssetText {
  color: var(--brand-black);
  font-weight: 700 !important;
}
.input {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  height: 32px;
  border: 1px solid var(--border-med);
}
.input:active,
.input:focus:focus-visible {
  outline: none;
  border: 1px solid var(--border-dark) !important;
}
.input::placeholder {
  color: var(--brand-black-semi-transparent);
}
/* Chrome, Safari, Edge, Opera */
.input[type='number']::-webkit-outer-spin-button,
.input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
}

.bottomContent .content {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--brand-gray);
  text-align: center;
}
.content svg {
  margin-left: 8px;
  color: var(--brand-green);
}
.bottomContent {
  width: 100%;
  padding-top: 12px;
}
.bottomContentBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.postLoadMsg {
  color: var(--brand-gray);
  text-align: center;
  margin: 6px 0 0 0;
  font-size: 12px;
  font-style: italic;
}
