.customNumberInput {
  width: 56px;
}
.date {
  font-weight: 700;
  color: var(--brand-pink);
  font-size: 14px;
  line-height: 19px;
}
.buttons {
  flex-wrap: wrap;
}
@media (max-width: 500px) {
  .container {
    min-width: 90px;
  }
  .customNumberInput {
    width: 50%;
  }
  .votingPeriod {
    margin-top: 20px !important;
  }
}
@media (max-width: 375px) {
  .customNumberInput {
    width: -webkit-fill-available;
  }
}
