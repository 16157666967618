.sidebarContainerCard {
  border: 1px solid var(--border-med);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
}

.sidebarContainerCard button {
  width: 100%;
  border-radius: 50px;
  margin-top: 18px;
}

.subtitle {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--brand-gray);
}

.sideCardHeader {
  font-weight: 700;
  display: flex;
  gap: 16px;
  align-items: center;
}
.sideCardHeader svg {
  width: 24px;
  height: 24px;
}

.title {
  font-size: 18px;
  margin-bottom: 0;
}

.icon {
  width: 44px;
  height: 44px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.greenIcon {
  background: var(--brand-green-hint);
  border: 1px solid var(--brand-green-semi-transparent);
}
.greenIcon svg {
  color: var(--brand-green);
}

.purpleIcon {
  background: var(--brand-purple-hint);
  border: 1px solid var(--brand-purple-semi-transparent);
}
.purpleIcon svg {
  color: var(--brand-purple);
}

.grayIcon {
  background: var(--brand-gray-hint);
  border: 1px solid var(--brand-gray-semi-transparent);
}
.grayIcon svg {
  color: var(--brand-gray);
}
.blackIcon {
  border: 1px solid var(--border-med);
}

.sideCardBody {
  font-size: 14px;
  margin-bottom: 0;
}

@media (max-width: 1200px) {
  .sidebarContainerCard {
    flex: 1;
    min-width: 250px !important;
    height: max-content !important;
  }
}
