.scoreAndIcon {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--brand-gray) !important;
}
.scoreAndIcon:hover {
  cursor: pointer;
}
.votingBtnsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--brand-gray) !important;
}
