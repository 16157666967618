.footerContainer {
  position: sticky !important;
  bottom: -1px;
  bottom: 0px;
  z-index: 1;
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.04) !important;
  width: 100%;
  max-width: 100%;
  z-index: 10 !important;
}
.footerPadding {
  padding: 13px 20px 13px 18px;
}
.noVotesContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.noVotesMessage {
  text-align: center;
  margin-bottom: 0px;
}

@media (max-width: 575px) {
  .footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: auto;
  }
  .footerPadding .btnContainer {
    padding: 10px 0px 0px 0px;
  }
  .fullWidthButton {
    width: 100% !important;
  }
  .editModeButtons button {
    white-space: pre !important;
  }
  .voteCount {
    display: none !important;
  }
}
.crownNoun {
  display: flex;
}
.crownNoun img {
  height: 14px;
}
.voteCount {
  display: flex;
  align-items: center;
  gap: 5px;
}
.connectContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.proposalWindowButtons {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.editModeButtons {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .proposalWindowButtons {
    padding-bottom: 5px;
  }
}
