.container {
  display: flex;
  flex: 1;
}
.periodButton {
  width: 100%;
  border-radius: 10px !important;
}
@media (max-width: 500px) {
  .periodButton {
    min-width: 120px;
  }
}
@media (max-width: 450px) {
  .periodButton {
    min-width: 100px;
  }
}
