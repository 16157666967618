.houseContainer {
  background: var(--bg-dark);
  min-height: 350px;
  padding: 1rem;
}

.stickyContainer {
  background: white;
  top: 0;
  position: sticky !important;
  z-index: 1;
  border-bottom: 1px var(--border-light) solid;
}

@media (max-width: 575px) {
  .houseContainer {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .stickyContainer {
    padding: 12px 0px 0px 0px;
  }
}
