.inputLabel {
  font-size: 18px;
  font-weight: 700;
  color: var(--brand-black-transparent);
}

.inputSection {
  margin: 2rem 0rem;
}
.inputInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputChars {
  font-size: 14px;
  color: var(--brand-gray);
}
.inputError {
  color: red;
  font-weight: 600;
  padding: 0.5rem 0rem;
  margin-bottom: 0rem;
}
.infRoundSectionsContainer {
  display: flex;
}
.infRoundTitleSection {
  width: 100%;
  padding-right: 3rem;
}
.fundReqFormGroup {
  max-width: 8rem;
}

.input {
  border: none;
  font-size: 20px;
  color: var(--brand-black);
  margin-bottom: 1.75rem;
  white-space: pre-line;
  margin-bottom: 0rem;
  padding: 0.75rem !important;
  font-size: 16px !important;
}
.input:focus {
  box-shadow: none;
  border: 1px rgba(0, 0, 0, 0.1) solid;
}
.input::placeholder {
  color: var(--brand-gray-transparent) !important;
  font-size: 16px !important;
  font-style: italic;
}
.input:focus::-webkit-input-placeholder {
  opacity: 0 !important;
}
.descriptionLabel {
  margin-bottom: 0;
}
.descriptionInput {
  height: 15rem;
}
.quillArea {
  width: 100%;
}
:global(.editPropContainer) .inputGroup,
:global(.editPropContainer) .inputSection:first-child {
  margin-top: 0 !important;
}

@media (max-width: 767px) {
  .infRoundSectionsContainer {
    display: flex;
    flex-direction: column;
  }
  .infRoundTitleSection {
    padding-right: 0rem;
  }
}
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor div div:nth-child(3) {
  border: none;
  border-radius: 0.25rem 0.25rem 0 0;
}
.editor div div:nth-child(4) {
  border: none;
  border-radius: 0 0 0.25rem 0.25rem;
}
