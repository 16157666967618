.primaryCard {
  width: 66.666667%;
  border: 1px solid var(--border-light);
  box-shadow: var(--shadow-low);
  height: max-content;
  min-height: 200px;
}
@media (max-width: 991px) {
  .primaryCard {
    width: 100%;
    margin-top: 8px;
  }
}
