:is(.votesInput, .tokenIdInput) {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 2px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  height: 32px;
  width: 100%;
  border: 1px solid var(--border-med);
}
:is(.votesInput, .tokenIdInput)::placeholder {
  color: var(--brand-gray-transparent) !important;
}
/* Chrome, Safari, Edge, Opera */
:is(.votesInput, .tokenIdInput)::-webkit-outer-spin-button,
:is(.votesInput, .tokenIdInput)::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
/* Firefox */
:is(.votesInput, .tokenIdInput) {
  -moz-appearance: textfield !important;
}

.fullWidth {
  width: 100%;
}
