.cardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: transparent !important;
  border-color: var(--border-med);
}
.imgAndName {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.star {
  margin-right: 12px;
}
.whiteBg {
  background-color: white !important;
}
.image {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  margin-right: 12px;
}
.name {
  font-weight: bold;
  font-size: 14px;
}
