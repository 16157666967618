.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
}
.addressContainer {
  flex: 1;
}
.addressInput {
  min-width: 100%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 2px 8px;
  font-weight: 500;
  height: 32px;
  width: 100%;
  border: 1px solid var(--border-med);
  font-size: 14px;
}
.addressInputError {
  border: 1px solid red !important;
}
.addressInput::placeholder {
  color: var(--brand-gray-transparent) !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.addressAndTitle {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.voteInput {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 2px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  height: 32px;
  width: 43px;
  border: 1px solid var(--border-med);
}
/* Chrome, Safari, Edge, Opera */
.voteInput::-webkit-outer-spin-button,
.voteInput::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
/* Firefox */
.voteInput {
  -moz-appearance: textfield !important;
}
.allotButtons {
  display: flex;
  flex-direction: row;
  gap: 2px;
}
.button {
  line-height: 19px;
  height: 32px;
  font-weight: 900;
  font-size: 20px !important;
}
.awardInput input {
  width: 100%;
}
.xButton {
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--border-light);
  border-radius: 8px;
  color: var(--brand-gray);
  font-weight: 600;
  font-size: 12px !important;
  line-height: 19px;
  text-align: center;
}
.addressSuccess {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  background: white !important;
  cursor: 'pointer';
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 2px 12px !important;
  font-weight: 500;
  height: 32px;
  width: 100%;
  border: 1px solid var(--border-med) !important;
  font-size: 14px;
  border-radius: 12px !important;
}
.addressSuccess span {
  color: var(--brand-black);
  font-weight: 700;
}
.addressImgAndTitle {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
}
/* .addressImgAndTitle span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-right: 5px;
} */
.addressImgAndTitle img {
  border-radius: 12%;
  max-width: 16px;
  max-height: 16px;
  object-fit: cover;
}
.error {
  color: red;
  font-size: 12px;
  font-weight: 700;
  font-style: italic;
}
.buttons {
  display: flex;
  gap: 8px;
}
.xButtonMobile {
  min-width: max-content;
}
@media (min-width: 501px) {
  .xButtonMobile {
    display: none;
  }
}
@media (max-width: 500px) {
  .container {
    flex-direction: column;
    width: 100%;
  }
  .addressAndTitle {
    min-width: 100%;
  }
  .allotButtons button {
    border-radius: 8px;
  }
  .buttons {
    justify-content: space-between;
  }
  .xButton {
    width: min-content !important;
    border-radius: 8px !important;
  }
  .xButtonDesktop {
    display: none;
  }
  .clear {
    width: auto;
  }
}
